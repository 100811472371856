.cards {
  content-visibility: auto;
  padding: 2rem 6rem 2rem 6rem;
  background: #fff;
  box-shadow: 0px -2px 50px 10px #000000b9;
}

.card__title {
  text-align: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
  font-size: 50px;
  text-shadow: 4px 4px rgb(0, 173, 181, 0.5);
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 50px;
  width: 100%;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__inner__box {
  flex: 1;
  position: relative;
}

/* affects size */
.cards__item__pic-wrap {
  position: relative;
  padding-top: 67%;
  overflow: hidden;
  height: 15vw;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: var(--badge-color);
  box-sizing: border-box;
  z-index: 1;
}

/* affects size */
.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s linear;
  z-index: 1;
}

/* affects size */
.cards__item__img__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s linear;
  z-index: 0;
}

/* controls what happens on hover (increases scale) */
/* it may be cool to do grayscale to color or play video */
.cards__item__img:hover {
  opacity: 0;

  transform: scale(1.2);
  transition: all 300ms ease-in-out;
}

.cards__item__info {
  padding: 30px 30px 30px;
  background-color: rgba(17, 17, 17, 0.7);
  background-blend-mode: darken;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.cards__item__text {
  color: #ffffff;
  text-align: center;
  width: 70%;
}

/* only displays when width */
@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

/* when a width is reached, allow the lists of
  cards to fill the space horizontally instead of
  vertically */
@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

/* When the cards are stacked (mobile), a small
  amount of space is added to prevent them from
  touching on the bottom and top */
@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale3d(1.3, 1.2, 1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@keyframes fadeOut{
  0% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
  100% {
    opacity:0;
    transform: translate3d(1.3, 1.2, 1);
  }
}


