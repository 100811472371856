.content {
  --project-color: black;

  z-index: 10;
  padding-top: 130vh;
  background-color: #fff;
  position: relative;
  .text {
    font-size: 1.2rem;
    line-height: 1.5;
  }


  .one-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
  }

  .two-column {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
  }

  .left-column {
    padding-right: 1rem;
  }

  .right-column {
    padding-left: 1rem;
  }



  

  .list {
    li {
      list-style: none;
      border-radius: 2rem;
      margin-bottom: 0.5rem;
    }
    &.horizontal {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        margin-right: 1rem;
      }
    }
  }

  .section-subtitle {
    color: var(--project-color);
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: 'Cabin', sans-serif;
  }

  .section-capture {
    font-size: 28px;
    font-weight: 200;
    letter-spacing: 1px;
  }

  .section-title {
    color: var(--project-color);
    font-size: 40px;
    letter-spacing: 2px;
    font-weight: 700;
  }

  

  .timeline {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    list-style: none;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        border-left: 2px dashed #cccccc;
      }
    .timeline-item {
      position: relative;
      width: 100%;
      margin-bottom: 2rem;
      padding-left: 2rem;
      --subline-border: #eaeaea;
      --subline-background: #eeeeee;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -1rem;
        width: 1rem;
        height: 100%;
        border: var(--subline-border) solid 2px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        background: var(--subline-background);
        border-left: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .timeline-item__content {
        position: relative;
        padding: 1rem 2rem;
        border-radius: 0.25rem;
        background-color: #fff;
        box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px, rgb(14 30 37 / 20%) 0px 2px 8px 0px;
        &:before {
          content: '';
          position: absolute;
          top: 1rem;
          left: -0.5rem;
          width: 0;
          height: 0;
          border-top: 0.5rem solid transparent;
          border-right: 0.5rem solid #ffffff;
          border-bottom: 0.5rem solid transparent;
        }
      }
      .timeline-item__date {
        display: inline-block;
        font-size: 0.75rem;
        font-weight: 700;
        color: #999;
      }
      .timeline-item__title {
        margin-top: 0.25rem;
        font-size: 1rem;
        font-weight: 700;
        color: #333;
      }
      .timeline-item__text {
        > p {
          margin-top: 0.25rem;
          color: #999;
          font-size: 0.875rem;
          > strong {
            color: #888;
          }
        }
      }
      .timeline-item__link {
        display: inline-block;
        margin-top: 0.5rem;
        font-size: 0.875rem;
        color: #999;
      }
      .timeline-item__link:hover {
        color: #555;
      }
      .timeline-item__img {
        display: block;
        width: 100%;
        max-width: 100%;
        margin-top: 1rem;
      }
    }
  }




  .list-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    &.fixed { 
      width: auto;
      height: 300px;
    }
  }

  .my-1 {
    margin: 1rem 0;
  }

  .my-2 {
    margin: 2rem 0;
  }

  .mb-3 {
    margin-bottom: 3rem;
  }

  .mb-4 {
    margin-bottom: 4rem;
  }

  .mb-5 {
    margin-bottom: 5rem;
  }

  .mb-6 {
    margin-bottom: 6rem;
  }

  .mb-7 {
    margin-bottom: 7rem;
  }

  .mb-8 {
    margin-bottom: 8rem;
  }

  .mr-2 {
    margin-right: 2rem;
  }

  .mr-3 {
    margin-right: 3rem;
  }

  .mr-4 {
    margin-right: 4rem;
  }

  .text-bold {
    font-weight: 700;
  }
}


.banner-title-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 11vh;
    font-weight: 100;
    &.top {
      z-index: 3;
    }
    &.middle {
      z-index: 2;
    }
    &.bottom {
      z-index: 0;
    }
  }

  .banner-title-btn {
    position: absolute;
    bottom: 2vh;
    width: 100%;
    z-index: 4;
    display: flex;
    justify-content: center;
  }

  // text to go over the banner image of the page
  .banner-title {
    font-size: 7em;
    font-weight: 100;
    position: absolute;
    color: #000000;
    text-align: center;
    font-family: 'KG OUTLINE', 'Courier New';
    z-index: 3;
  }

  .banner-title-bg {
    font-size: 7em;
    font-weight: normal;
    position: absolute;
    color: #000000;
    text-align: center;
    font-family: 'KG SOLID', 'Courier New';
  }

  .banner-dots {
    position: absolute;
    width: 100%;
    z-index: 3;
    bottom: 12vh;
  }

  .banner {
    position: fixed;
    pointer-events: none;
    overflow-y: clip;
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .banner-icon i {
    color: rgba(255, 255, 255, 0.8);
  }

  .banner-img {
    pointer-events: auto;
    position: absolute;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      pointer-events: none;
    }
    > div > span {
      pointer-events: auto;
      position: absolute;
      height: 100%;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      transition: opacity 0.2s ease;
    }
    
    z-index: 1;
  }

  .width-spanned-list {
    width: 100%;
    display: flex;
    justify-content: center;
    
    li {
      margin: 2rem 2rem;
      padding: 1rem 2rem;
      text-align: center;
      list-style: none;
      text-align: center;
      color: #fff;
      
    }
  }