.gallery-page {
  --gallery_link_color: #22276e;
}

.gallery-title {
  width: 100%;
  display: flex;
  justify-content:center;
  align-items:center;
  flex-direction: column;
  padding: 8rem 0 0 0;
  margin: 12px 0 12px 0;
}

.gallery-title-box {
  width: 80%;
  height: 100%;
}

.gallery-title-text {
  margin-bottom: 24px;
}

.gallery-title h1 {
  text-align: center;
  font-size: 50px;
  text-shadow: 4px 4px rgb(0, 173, 181, 0.5);
}

.gallery-title h2 {
  font-family:'Courier New', Courier, monospace;
  text-align: center;
  font-style: italic;
  color: #22276e;
}

.gallery-subtitle {
  display: flex;
  justify-content: center;
}

.gallery-subtitle a {
  position: relative;
  color: #7278cc;
  text-decoration: none;
  text-shadow: 3px 3px rgb(0, 173, 181, 0.2);
  cursor: pointer;
  transition: color 0.3s ease;
}

.gallery-subtitle a:hover {
  color: var(--gallery_link_color);
}

.gallery-subtitle a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--gallery_link_color);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.gallery-subtitle a:hover::before {
  transform: scaleX(1);
}

.gallery-subtitle-links {
  color: var(--gallery_link_color);
  text-decoration: none;
}

.gallery-wrapper {
  width: 100%;
  columns: 3;
  padding: 12px 12px;
}

.gallery-wrapper .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.gallery-wrapper .pics:hover {
    filter: opacity(.8);
}

.images {
    height: auto;
    width: 100%;
}

@media (max-width: 991px) {
  .gallery-wrapper {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 480px) {
  .gallery-wrapper {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;

    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}

