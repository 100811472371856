:root {
  --marquee-height: 16vh;
  --marquee-width: 90vw;
  --marquee-elements: 9;
  --i-marquee-elements: 18;
  --title-shadow-color: #005892;
  --marquee-elements-displayed: 5;
  --i-marquee-elements-displayed: 6;
  --marquee-gradient-color: #59b5c4;
  --marquee-element-width: calc(
    var(--marquee-width) / var(--marquee-elements-displayed)
  );
  --i-marquee-element-width: calc(
    var(--marquee-width) / var(--i-marquee-elements-displayed)
  );
  --marquee-animation-duration: calc(var(--marquee-elements) * 1.4s);
  --i-marquee-animation-duration: calc(var(--i-marquee-elements) * 1.6s);
}



.slider {
  content-visibility: auto;
  padding: 4rem 6rem 2rem 8rem;
  background: var(--marquee-gradient-color);
  z-index: 3;
  position: relative;
  width: 100%;
  box-shadow: 0px -2px 10px 2px #2b4e5080;
}

.slider-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.slider-top a {
  position: relative;
  color: #fee;
  text-decoration: none;
  cursor: pointer;
}

.slider-top a:hover {
  color: #fee;
}

.slider-top a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fee;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.slider-top a:hover::before {
  transform: scaleX(1);
}

.slider-top h2 {
  font-family:'Courier New', Courier, monospace;
}

.slider-title-sub {
  width: 20%;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  align-items: center;
}

.slider-links {
  color: #fee;
  text-decoration: none;
}

.slider-title {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 50px;
  color: #fff;
  text-shadow: 4px 4px var(--title-shadow-color);
}

.slider-title-left {
  text-align: center;
  word-spacing: 30px;
  font-size: larger;
}

.slider-title-right {
  text-align: center;
  font-size: larger;
  font-style: italic;
}

.marquee-background {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: black;
  height: 22vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marquee {
  width: var(--marquee-width);
  height: var(--marquee-height);
  background-color: #eee;
  color: rgb(29, 85, 131);
  overflow: hidden;
  position: absolute;
}

.i-marquee {
  width: var(--marquee-width);
  height: var(--marquee-height);
  background-color: #eee;
  color: rgb(42, 53, 90);
  overflow: hidden;
  position: absolute;
}

.marquee:before,
.marquee:after,
.i-marquee:before,
.i-marquee:after {
  position: absolute;
  top: 0;
  width: 10rem;
  height: 100%;
  content: "";
  z-index: 1;
}

.i-marquee:before,
.marquee:before {
  left: 0;
  background: linear-gradient(to right, var(--marquee-gradient-color) 0%, transparent 100%);
}

.i-marquee:after,
.marquee:after {
  right: 0;
  background: linear-gradient(to left, var(--marquee-gradient-color) 0%, transparent 100%);
}

.marquee-content {
  list-style: none;
  height: 100%;
  display: flex;
  animation: scrolling var(--marquee-animation-duration) linear infinite;
}

.i-marquee-content {
  list-style: none;
  height: 100%;
  display: flex;
  animation: i-scrolling var(--i-marquee-animation-duration) linear infinite;
}

@keyframes scrolling {
  0% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(
      calc(-1 * var(--marquee-element-width) * var(--marquee-elements))
    );
  }
}

@keyframes i-scrolling {
  0% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(
      calc(-1 * var(--i-marquee-element-width) * var(--i-marquee-elements))
    );
  }
}

.marquee-content:hover,
.i-marquee-content:hover {
  animation-play-state: paused;
}

.marquee-content li {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 3px solid teal; */
  width: var(--marquee-element-width);
  flex-shrink: 0;
  font-size: calc(var(--i-marquee-element-width) * 1 / 7);
  white-space: nowrap;
}

.i-marquee-content li {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 3px solid teal; */
  width: var(--i-marquee-element-width);
  flex-shrink: 0;
  font-size: calc(var(--marquee-height) * 3/5);
  white-space: nowrap;
}

@media (max-width: 600px) {
  :root {
    --marquee-width: 100vw;
    --i-marquee-width: 100vw;
    --marquee-height: 12vh;
    --marquee-elements-displayed: 4;
    --i-marquee-elements-displayed: 5;
  }
  .marquee:before,
  .marquee:after,
  .i-marquee:before,
  .i-marquee:after {
    width: 5rem;
  }
  .slider {
    padding: 4rem 2rem 2rem 1rem;
  }
}
