mark.light-blue {
  color: #a1fcff;
  font-size: 80px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  background: none;
  transition: color 0.2s ease-in-out;
}

mark.light-blue:hover {
  color: #3ecfd4;
}



mark.dark-blue {
  color: #3382c4;
  background: none;
}

.piano {
  object-fit: cover;
  height: 100vh;
  filter: brightness(90%) contrast(106%);
  width: 100%;
  position: fixed;
  z-index: -3;
}

.intro-invisible-width {
  height: 100vh;
  width: 100%;
  justify-content: center;
  position: relative;
  pointer-events: none;
}

.intro-container {
  /* background: url('images/img-home.jpg') center center/cover no-repeat; */
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  z-index: 0;
  pointer-events: none;

  > span > span {
    pointer-events: none !important;
  }
}

.intro-parallax {
  pointer-events: none;
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.intro-text {
  margin-top: 1em;
  pointer-events: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 80px;
  display: flex;
  color: #eeeeee;
  text-align: center;
  justify-content: center;
  width: 100%;

  --roller-height: 100px;
}

.intro-text div {
  height: var(--roller-height);
  z-index: -1;
  overflow: hidden;
  margin-left: 0.25em; /* empty space between a and title scroller */
  position: relative;
}

.intro-text div ul {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: left;
  animation: text-roller 15s ease-in-out infinite;
}

@keyframes text-roller{
  0%{
    transform: translateY(0px);
  }
  20%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(calc(-1 * var(--roller-height)));
  }
  45%{
    transform: translateY(calc(-1 * var(--roller-height)));
  }
  50%{
    transform: translateY(calc(-2 * var(--roller-height)));
  }
  70%{
    transform: translateY(calc(-2 * var(--roller-height)));
  }
  75%{
    transform: translateY(calc(-3 * var(--roller-height)));
  }
  95%{
    transform: translateY(calc(-3 * var(--roller-height)));
  }
  100%{
    transform: translateY(calc(-4 * var(--roller-height)));
  }
}

.intro-text div ul li {
  display: flex;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 80px;
  list-style-type: none;
  height: var(--roller-height);
}

.uiux-header {
  color: #6afcfc;
  letter-spacing: 0.1em;
}

.software-header {
  color: #11ece9;
}

.frontend-header {
  color: #74ebc9;
  letter-spacing: 0.08em;
}

.fullstack-header {
  color: #4ae4e4;
}

.intro-parallax > p {
  margin-top: 12px;
  color: #00e7f3;
  font-size: 20px;
  font-weight: bolder;
  width: 100%;
  text-align: center;
}

.intro-btns {
  pointer-events: all;
  margin-top: 32px;
  /* controls the distance of the intro section from the top */
  margin-bottom: 58px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-btns a {
  margin: 0 20px 0 20px;
  position: relative;
  color: #fee;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.intro-btns a:hover {
  color: #4ab4f1;
}

.intro-btns a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fee;
  transform: scaleX(0);
  transition: all 0.2s ease;
}

.intro-btns a:hover::before {
  transform: scaleX(1);
  background-color: #4ab4f1;
}

.fa-play-circle {
  margin-left: 4px;
}

.to-cards-button {
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s 1.6s opacity ease-in-out;
}

.to-cards-arrow i {
  min-width: 40px;
  color: #fff;
  background: transparent;
  font-size: 40px;
  transition: 250ms all ease;
  -webkit-transition: 250ms all ease;
}

.to-cards-arrow i:hover {
  color: #4ab4f1;
  transform: translate3d(0px, 15px, 0px);
}

.bottom-made-in-react {
  position: absolute;
  color: #6afcfc;
  z-index: 100;
  text-shadow: 0 1px 0 #358383,
               0 20px 20px rgba(0,0,0,.15);
  right: 15px;
  bottom: 12px;
  padding-right: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s all ease-in-out;
}

.react-logo{
  right: 2px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #00d8ff;

  animation-name:rotate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

}

.react-logo , .react-logo:before , .react-logo:after{
  position:absolute;
  display: inline-block;
  width: 8px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #00d8ff;
}

.nucleo , .react-logo , .react-logo:before , .react-logo:after {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nucleo{
  width: 4px;
  height: 4px;
  border-radius:100%;
  background:#00d8ff;
}

.react-logo:before , .react-logo:after{
  content:"";
}

.react-logo:after{
  transform:rotate(-57deg);
}

.react-logo:before{
  transform:rotate(57deg);
}

.title{
font-size:60px;
background:#222;
color:#00d8ff;
width:250px;
margin:2px auto;
text-align:center;
}

@keyframes rotate{

  100%{
    transform:rotate(360deg);
  }
}

@media screen and (max-width: 1320px) {
  .intro-text {
    align-items: center;
    flex-direction: column;
  }
  .intro-text div {
    margin-left: 0em; /* empty space between a and title scroller */
  }
  .intro-text div ul li {
    justify-content: center;
  }
  .intro-parallax > p {
    margin-top: 30px;
    line-height: 150%;
    width: 70%;
  }
}

@media screen and (max-width: 960px) {

  .intro-parallax {
    position: absolute;

    h1 {
      font-size: 60px;
      margin-top: -50px;
    }
    div ul li {
      font-size: 60px;
    }
    p {
      font-size: 18px;
      margin-top: 15px;
      line-height: 150%;
    }
  }

  .intro-text {
    --roller-height: 80px;
  }d
  
  mark.light-blue {
    font-size: 65px;
  }
}

@media screen and (max-width: 768px) {
  .intro-parallax > h1 {
    font-size: 50px;
    margin-top: 30px;
    width: 80%;
  }
  .intro-text {
    --roller-height: 140px;
  }
  .intro-text div ul li {
    font-size: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .intro-parallax > p {
    font-size: 16px;
    text-align: justify;
    margin-top: 10px;
    line-height: 180%;
  }
  .intro-btns a {
    margin: 0 5px 0 5px;
    font-size: 10px;
  }
  mark.light-blue {
    font-size: 55px;
  }

  .btn {
    width: 100%;
  }
}
