.footer-container {
  content-visibility: auto;
  background-color: hsl(0, 0%, 14%);
  box-shadow: 0px -2px 10px 2px #242424b9;
  padding: 10vh 0 30vh 0;
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: relative;
}

.footer-parallax {
  position: absolute;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 16px;
  text-align: center;
  width: 80%;
  box-sizing: border-box;
}

.footer-link-items h2 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  font-size: 400%;
  color: #fff;
}

.footer-link-items p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 125%;
  line-height: 3rem;
  margin: 1rem 0 3rem 0;
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */

.social-icon-link i {
  min-width: 40px;
  color: #fff;
  background: transparent;
  font-size: 40px;
  transition: 250ms all ease;
  -webkit-transition: 250ms all ease;
}

.social-icon-link i:hover {
  transform: translate3d(0px, -12px, 0px);
}

.github i:hover {
  color: #4267b2;
}

.holder {
  position: relative;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: block;
}

.instagram-background i {
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #ffc35b,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #e41971, transparent),
    linear-gradient(
      #2a15e7,
      #da0f9a 30%,
      #fd2450 50%,
      #f16422 70%,
      #ffb236 100%
    );
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.instagram i {
  color: #fff;
  font-size: 40px;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 250ms all ease;
}

.email i:hover {
  color: #ea4335;
}

.twitter i:hover {
  color: #1da1f2;
}

.linkedin i:hover {
  color: #2867b2;
}

.to-top-arrow i:hover {
  color: #58deff;
  transform: scale(1.4, 1.4) translate3d(0px, -10px, 0px);
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  max-width: 1000px;
  margin: 10px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-self: center;
  margin-bottom: 16px;
}

.to-top-button {
  position: absolute;
  width: 100%;
  margin-top: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s all ease-in-out;
}

/* enables mobile compatibility */
@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}
