* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Comfortaa', sans-serif;
}

:root {
  --Navbar_right_offset: 0;
}

/* .animated-router {
  overflow-x: hidden;
} */

.home,
.services,
.products,
.sign-up {
  display: flex;
  width: 100%;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

@media (max-width: 600px) {
  html {
    font-size: 10px;
  }
}