mark.light-red {
  color: #f55a3e;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  background: none;
}

mark.small-red {
  color: #f55a3e;
  font-size: 1.23rem;
  background: none;
}

mark.red {
  color: #f55a3e;
  background: none;
}

.profile {
  /* background: url('images/img-home.jpg') center center/cover no-repeat; */
  height: 100vh;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.profile-top-text {
  display: flex;
  flex-direction: column;
}

.profile-top-text a {
  position: relative;
  color: #f55a3e;
  text-decoration: none;
  cursor: pointer;
}

.profile-top-text a:hover {
  color: #f55a3e;
}

.profile-top-text a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f55a3e;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.profile-top-text a:hover::before {
  transform: scaleX(1);
}

.profile-top {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
}

.profile-img {
  z-index: -1;
  height: 100vh;
  overflow-x: hidden;
}

.profile-top-container {
  display: flex;
  position: absolute;
  top: 80px;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  z-index: 1;
}

.profile-top-content {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 0;
  right: 0;
  margin-top: 35vh;
  margin-right: 10vw;
}

.profile-top-text {
  backdrop-filter: blur(2px) contrast(70%) brightness(120%);
}

.profile-top-text h2 {
  padding-bottom: 3vh;
  font-size: 1.6rem;
  text-align: end;
}

.profile-top-text p {
  margin-top: 2vh;
  font-size: 1.1rem;
  text-align: end;
}

.profile-dark {
  color: rgb(0, 0, 0);
  z-index: 3;
}

.about-footer-button {
  margin: 2rem 0 0 6rem;
  display: flex;
  justify-content: center;
  transition: 1s color ease-in-out, 1s opacity ease-in-out;
}

.about-footer-arrow i {
  min-width: 40px;
  color: #000000;
  background: transparent;
  font-size: 40px;
  transition: 250ms all ease;
  -webkit-transition: 250ms all ease;
}

.about-footer-arrow i:hover {
  color: #f55a3e;
  transform: translate3d(0px, 15px, 0px);
}

.text-over-image {
  position: absolute;
  padding-top: 10vh;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
}

.text-over-image h1 {
  position: absolute;
  font-size: 5rem;
  width: max-content;
  color: #000000;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 120px;
  z-index: 0;
  
}


/* credit to Jake Jarvis on https://jarv.is/notes/css-waving-hand-emoji/ */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

@media screen and (max-width: 960px) {
  .text-over-image h1 {
    margin-top: 5vh;
    font-size: 3rem;
  }

  .profile-top-content {
    margin: 0 0 0 0;
    margin-top: 100px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }

  .profile-top-text {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .profile-top-content h2 {
    width: 80%;
    padding-bottom: 3vh;
    font-size: 1rem;
    text-align: center;
  }

  .profile-top-content p {
    width: 80%;
    margin-top: 2vh;
    font-size: 1em;
    text-align: center;
  }
  mark.small-red {
    font-size: 1em;
  }

  .about-footer-button {
    margin: 1em 0 0 0;
    display: flex;
    justify-content: center;
    transition: 1s color ease-in-out, 1s opacity ease-in-out;
  }

  .profile-img {
    opacity: 0.3;
  }
}
