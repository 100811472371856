.navbar {
  background: var(--background);
  padding-right: calc(var(--Navbar_right_offset) * 1px);
  height: 80px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  z-index: 999;
  --background: #fff;
  --mobilebackground: #fff;
  --purewhite: #fff;
  --darkgray: #fff;
  --vivid: #2373db;
  --rotate: 0deg;

  transition: 0.8s color;
}

.navbar-invisible-box {
  top: 0;
  /* height: 80px; */
  width: 100%;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
}

.navbar-name {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.navbar-name-middle {
  position: absolute;
  color: rgba(255, 57, 43, 0.76);
}

.navbar-name-backdrop {
  position: absolute;
  color: rgba(0, 171, 180, 0.349);
}

.navbar-logo {
  color: var(--purewhite);
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.navbar-name-front i {
  transition: 1.6s color ease-in-out, 0.8s transform ease-in-out;
  transform: rotate(var(--rotate));
  color: var(--vivid);
}

.navbar-name-middle i {
  transition: 0.9s transform ease-in-out;
  transform: rotate(calc(var(--rotate) * 1));
}

.navbar-name-backdrop i {
  transition: 1s transform ease-in-out;
  transform: rotate(calc(var(--rotate) * 1));
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  overflow-y: hidden;
}

.nav-item {
  height: 80px;
  margin-left: 10px;
  
  &.switch {
    transition: transform 1s ease;
    transform: translateY(0);
    &.active {
      transform: translateY(-100%);
    }
    :nth-child(2) {
      color: rgba(0, 171, 180, 1);
    }
  }
}

.nav-links {
  color: var(--purewhite);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 0px solid #fff;
  justify-content: center;
}

.nav-links:hover {
  border-bottom: 4px solid var(--purewhite);
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: var(--purewhite);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1320px) {
  .nav-menu {
    width: 60vw;
  }
}


@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 95vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: background 0.5s ease, height 0s, opacity 0.5s ease, left 0.5s ease;
  }

  .nav-menu.active {
    background: var(--mobilebackground);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: var(--purewhite);
    color: var(--darkgray);
    border-radius: 0;
  }

  .navbar.active {
    background-color: var(--mobilebackground);
  }

  .navbar-container {
    justify-content: space-between;
    align-items:center;
  }

  .navbar-logo {
    /* The distance the name and logo are from the left side */
    margin-left: 1.5rem;
  } 

  .menu-icon {
    display: block;
    margin-right: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--purewhite);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: var(--purewhite);
    padding: 14px 20px;
    border: 1px solid var(--purewhite);
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: var(--purewhite);
    color: var(--darkgray);
    transition: 250ms;
  }

  .nav-item {
    &.switch {
      transition: opacity 1s ease;
      transform: none;
      
      &.active {
        :nth-child(1) {
          opacity: 0%;
        }
        :nth-child(2) {
          opacity: 100%;
        }
      }
      :nth-child(1) {
        opacity: 100%;
      }
      :nth-child(2) {
        opacity: 0%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  
}